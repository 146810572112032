'use client'

import { Button, Heading } from '@chakra-ui/react'
import { AppLink } from 'ui'
import { InnerPageWrapper } from '#/src/app/(application)/_components/InnerPageWrapper'
import { InnerPageHeader } from '#/src/app/(application)/_components/InnerPageHeader'

export default function NotFound() {
  return (
    <InnerPageWrapper alignItems="center">
      <InnerPageHeader />
      <Heading variant="main" textAlign="center">
        Not Found
      </Heading>
      <p>Could not find requested resource</p>
      <Button as={AppLink} href="/app" width="80%">
        Return Home
      </Button>
    </InnerPageWrapper>
  )
}
